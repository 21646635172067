<script>
var sidebarMenuArray = [];

// if (localStorage.getItem("userSettings") != null) {
//   //var userSetting = localStorage.getItem('userSettings');
//   var userSettingJSON = JSON.parse(localStorage.getItem("userSettings"));
//   if (
//     userSettingJSON.Nombre == "Admin" &&
//     userSettingJSON.NumeroEmpleado == 1000
//   ) {
//     // console.log("Usuario Conectado: "+userSettingJSON.Nombre);
//     sidebarMenuArray = [
//       {
//         path: "/Catalogos",
//         icon: "fa fa-list-ol",
//         title: "Catalogos",
//         children: [
//           { path: "/Catalogos/clientes", title: "Clientes" },
//           { path: "/Catalogos/usuarios", title: "Usuarios" },
//         ],
//       },
//       {
//         path: "/Reportes",
//         icon: "fa fa-table",
//         title: "Reportes",
//         children: [
//           // { path: '/Reportes/monitortanques', title: 'Monitor de tanques' },
//           {
//             path: "/Reportes/cre",
//             title: "CRE",
//             children: [{ path: "/Reportes/cre/xmlcre", title: "XML CRE" }],
//           },
//           {
//             path: "/Reportes/Facturacion",
//             title: "Facturacion",
//             children: [
//               { path: "/Reportes/Facturacion/facturacion", title: "Clientes" },
//               { path: "/Reportes/menu-1-1/menu-2-3", title: "Global" },
//             ],
//           },
//           {
//             path: "/Reportes/Ventas",
//             title: "Ventas",
//             children: [{ path: "/Reportes/Ventas/ventas", title: "Ventas" }],
//           },
//         ],
//       },
//       {
//         path: "/Facturacion",
//         icon: "fa fa-list-ol",
//         title: "Facturacion",
//         children: [
//           {
//             path: "/Facturacion/ServiciosAdministrativos",
//             title: "Servicios Administrativos",
//           },
//           { path: "/Facturacion/FacturacionRCH", title: "Facturacion RCH" },
//           { path: "/Facturacion/FacturacionUSFUEL", title: "Facturacion RCH" },
//         ],
//       },
//       {
//         path: "/monitortanques",
//         icon: "fa fa-gas-pump",
//         title: "Monitor de tanques",
//       },
//     ];
//   } else {
//     sidebarMenuArray = [
//       {
//         path: "/reportes",
//         icon: "fa fa-table",
//         title: "Reportes",
//         children: [
//           {
//             path: "/resportes/cre",
//             title: "CRE",
//             children: [{ path: "/reportes/cre/xmlcre", title: "XML CRE" }],
//           },
//         ],
//       },
//     ];
//   }
// }

var userSetting = localStorage.getItem("userSettings");

if (userSetting != null) {
  //debugger; // eslint-disable-line no-debugger

  var pageUserSettings = JSON.parse(userSetting);
  // console.log(pageUserSettings);
  var permisos = pageUserSettings.Permisos;
  //  console.log(JSON.stringify(permisos));

  sidebarMenuArray = [];


  for (var n1 = 0; n1 < permisos.length; n1++) {
    var path = permisos[n1].Path;
    var level1 = {
      path: "/" + path,
      icon: "fa fa-list-ol",
      title: permisos[n1].Descripcion
    };

    if (permisos[n1].Descripcion == "Tanques") {
      level1.icon = "fa fa-gas-pump";
    } else {
      level1.children = [];
      for (var n2 = 0; n2 < permisos[n1].Submodulos.length; n2++) {
        var subpath = permisos[n1].Submodulos[n2].Path;
        var suboption = {
          path: "/" + subpath,
          title: permisos[n1].Submodulos[n2].Descripcion,
          children: [],
        };
        for (var n3 = 0; n3 < permisos[n1].Submodulos[n2].Permisos.length; n3++) {
          var thirdpath = permisos[n1].Submodulos[n2].Permisos[n3].Path;
          var thirdoption = {
            path: "/" + thirdpath,
            title: permisos[n1].Submodulos[n2].Permisos[n3].Descripcion,
            // children: [],
          };
          suboption.children.push(thirdoption);

          console.log(permisos[n1].Submodulos[n2].Permisos[n3].Path)
        }


        level1.children.push(suboption);
      }

    }

    sidebarMenuArray.push(level1);
  }




}










//console.log(JSON.stringify(sidebarMenuArray) );

const sidebarMenu = sidebarMenuArray;
export default sidebarMenu;

</script>
