<template>
    <div>
      <div class="loading-search" v-if="loading">
        <div class="fa-3x text-center">
          <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
        </div>
      </div>
      <!-- BEGIN breadcrumb -->
      <ol class="breadcrumb float-xl-end">
              <li class="breadcrumb-item"><a href="javascript:;">Compras</a></li>
              <li class="breadcrumb-item"><a href="javascript:;">-</a></li>
              <li class="breadcrumb-item active"><a href="javascript:;">Gasoinfo</a></li>
      </ol>
      <!-- END breadcrumb -->
      <!-- BEGIN page-header -->
      <h1 class="page-header">Gasoinfo</h1>
      <!-- END page-header -->

      
  
    <form @submit.prevent="search">   
      <div class="row">
        <div class="col-md-8"> 
            <div class="form-group row">
              
              <div class="col-md-3">
                  <label class="col-form-label">Fecha inicial</label>
                <div class="input-group">
                  <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                  <label for="dateTimeInput" class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </label>
                </div>
              </div>
  
              
              
              <div class="col-md-3">
                  <label class="col-form-label">Ciudad</label>
                  <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
                      <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                          {{ ciudad.Nombre }}
                      </option>
                  </select>
              </div>
              <div class="col-md-3">
                  <label class="col-form-label">Estación</label>
                  <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                    <option value="0">Todas</option>
                      <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                      {{ estacion.Nombre }}
                      </option>
                    </select>
              </div>
              <div class="col-md-3">

              </div>
  
             
  
              <!-- <div class="col-md-6">
                <button type="submit" class="btn btn-success me-1 mb-1">{{textoBotonBuscar}}</button>
              </div> -->
            </div>
        </div>
        <div class="col-md-2">
              <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{textoBotonBuscar}}</button>
        </div>
        <div class="col-md-2">
              <button type="button" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;" @click="guardarEnviar()">guardar/enviar</button>
        </div>
      </div>
  
    </form>
      <!-- <panel title="Gasoinfo" bodyClass="p-0" class="">
  
        <div class="panel-body-m"> -->
          
          


          <div class="col-md-12" style="margin-top: 30px; padding-left: 15px; padding-right: 15px;">
            <div class="row">
              <div class="col-md-12">
                <div class="row" style="background: #2d353c; color: #fff; height: 40px; padding-top: 10px;">
                  <div class="col-md-1"></div>
                  <div class="col-md-1"><b>Enviar</b></div>
                  <div class="col-md-1"><b>Estación</b></div>
                  <div class="col-md-1"><b>Nombre Estación</b></div>
                  <div class="col-md-2"><b>Producto</b></div>
                  <div class="col-md-1"><b>Inv Inicial</b></div>
                  <div class="col-md-1"><b>Venta</b></div>
                  <div class="col-md-1"><b>Compras</b></div>
                  
                  
                  <div class="col-md-1"><b>Inv Final</b></div>
                  <div class="col-md-1"><b>Mermas</b></div>
                  <div class="col-md-1"><b></b></div>
                </div>
              </div>
              <div class="col-md-12" v-for="(encabezado, index) in registrosGasoinfoOriginal" v-bind:key="index" style="border-top: 1px solid #888; border-right: 1px solid #888; border-left: 1px solid #888;">
                <div class="row" style="background: #eaeaea; height: 35px; box-shadow: 0px 6px 7px -3px #aaa; border-bottom: 1px solid #9f9f9f;"  v-bind:id="'encabezado-'+index"
                v-bind:class="{ 'separadorDiv': index > 0 && encabezado.Estacion != registrosGasoinfoOriginal[index-1].Estacion }"
                >
                  
                  <div class="col-md-1" ><button v-if="encabezado.Compras != null" class="btn btn-primary btn-xs" style="cursor: pointer; margin: 5px 2px;" @click="expandirDetalleCompras(index)">+</button> </div>
                  <div class="col-md-1">

                    <div class="form-check form-switch mb-2" v-if="(index > 0 && encabezado.Estacion != registrosGasoinfoOriginal[index-1].Estacion) || index == 0">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked2"
                        @click="aplicarCheckCallAPI(index)"
                        v-model="encabezado.AgregarAProductos"
                      />
                        <!-- v-model="detalle.Enviar" -->
                    </div>
                  </div>
                  <div class="col-md-1">{{encabezado.Estacion}}</div>
                  <div class="col-md-1">{{encabezado.EstacionNombre}}</div>
                  <div class="col-md-2">{{encabezado.Producto}}</div>
                  
                  <div class="col-md-1" style="padding-top: 3px;">
                    <input type="number" class="form-control" v-model="encabezado.InvInicial" step=".01" style="padding: 3px;" v-on:blur="recalcularMerma(index)">
                  </div>
                  <div class="col-md-1" style="padding-top: 3px;">
                    <input type="number" class="form-control" v-model="encabezado.VtasDia" step=".01" style="padding: 3px;" v-on:blur="recalcularMerma(index)">
                  </div>
                  <div class="col-md-1">
                    {{encabezado.VolumenCompras}}
                  </div>
                  <div class="col-md-1" style="padding-top: 3px;">
                    <input type="number" class="form-control" v-model="encabezado.InvFinal" step=".01" style="padding: 3px;" v-on:blur="recalcularMerma(index)">
                  </div>
                  
                  <div class="col-md-1">{{encabezado.Merma | formatNumber}}</div><!-- label resultado-->
                  <div class="col-md-1" style="padding-top: 3px;">
                    <button class="btn btn-xs btn-success" v-bind:id="'calcular-'+index" @click="calcularEnviar(index)">Guardar</button>
                  </div><!-- calcular-->

                  
                </div>
                <div class="row" v-if="encabezado.Compras" style="display: none;" v-bind:id="'detalle-'+index">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Enviar</th>
                          <th nowrap>Serie</th>
                          <th nowrap>Folio</th>
                          <th nowrap>Fecha</th>
                          <th nowrap>Cantidad</th>
                          <th nowrap>Precio</th>
                          <th nowrap>Flete</th>
                          <th nowrap>Importe</th>
                          <th nowrap>IVA</th>
                          <th nowrap>IVA Retención</th>
                          <th nowrap>IVA Descuento</th>
                          <th nowrap>Flete Sin IVA</th>
                          <th nowrap>Flete IVA</th>
                          <th nowrap>Descuento</th>
                          <th nowrap>Proveedor</th>
                          <th nowrap>Transportista</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(detalle, index2) in encabezado.Compras" v-bind:key="index2">
                          <td>
                            
                            <div class="form-check form-switch mb-2">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckChecked"
                                v-model="detalle.Enviar"
                                @click="enviarCheckCallAPI(index, index2)"
                              />
                            </div>

                          </td>
                          <td>{{detalle.Serie}}</td>
                          <td>{{detalle.Folio}}</td>
                          <td>{{detalle.FechaFactura}}</td>
                          <td>{{detalle.Cantidad | formatNumber}}</td>
                          
                          <td>{{detalle.Precio | currency}}</td>
                          <td>{{detalle.Flete | currency}}</td>
                          <td>{{detalle.Importe | currency}}</td>
                          <td>{{detalle.IVA | currency}}</td>
                          <td>{{detalle.IVARet | currency}}</td>
                          <td>{{detalle.IVADescuento | currency}}</td>
                          <td>{{detalle.FleteSinIva | currency}}</td>
                          <td>{{detalle.FleteIVA | currency}}</td>
                          <td>{{detalle.Descuento | currency}}</td>
                          <td>{{detalle.NombreProveedor}}</td>
                          <td>{{detalle.NombreTransportista}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>

          </div>
        <!-- </div>
        
      </panel> -->
      
  
      <!-- bottom left vue-notification -->
      <notifications group="top-right" position="top right" :speed="1000" />
    </div>
  </template>
  
  <script>
  
  import axios from "axios";
  // import { json } from "body-parser";
  import moment from 'moment';
  var numeral = require("numeral");
  import Vue from "vue";
  Vue.filter("formatNumber", function (value) {
      return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
  });
  
  Vue.filter("currency", function (value) {
      return numeral(value).format("$0,0.00"); 
  });

  Vue.filter("formatDate", function (value) {
    if (value) {
      return moment(String(value)).format('MM/DD/YYYY hh:mm')
    }
  });
  
  export default {
  async	created() {
    
      },
    
    mounted: function () {
        debugger; // eslint-disable-line no-debugger
        var userSetting = JSON.parse(localStorage.getItem('userSettings'));
        this.usuario = userSetting.Usuario;
        this.idUsuario = userSetting.NumeroEmpleado;
        this.nombreLogueado = userSetting.Nombre;
        this.usuarioEstacion = userSetting.Estacion;
        this.IdRol = userSetting.IdRol;
        this.getCiudades();
        this.ciudadSelect = userSetting.CiudadId;
        this.getEstaciones();
        this.estacionSelect = userSetting.Estacion;
        // this.GetTurnos();
  
      if(this.IdRol == 3 || this.IdRol == 7){
        this.deshabilitarCombos = true;
      }
  
      
  
    },
  watch:{
    contador(val){
      console.log(val);
    }
  },
    methods: {
      async SetCreGasoinfo(dataToSend){
        var self = this;
        self.textoBotonAplicar = "Aplicando...";
        self.loading = true;
        
          try {
            const response = await axios.post("CRE/SetCreGasoinfo", dataToSend);
            self.contadorSetCreGasoinfo++;
            self.loading = false;
            if (!response.data.Success) {
              this.alertMessage = response.data.Message;
              this.alertTitle = response.data.Error;
              this.show("top-right", "error");

            }
            // else{
              
            //   this.alertMessage = response.data.Message;
            //   this.alertTitle = "";
            //   this.show("top-right", "success");
            // }
            self.textoBotonAplicar = "Aplicar";
            if(self.productosGuardar.length == self.contadorSetCreGasoinfo){
                  this.alertMessage = response.data.Message;
                  this.alertTitle = "";
                  this.show("top-right", "success");
            }
            

          } catch (e) {
            self.loading = false;
            self.contadorSetCreGasoinfo++;

            this.alertMessage = "Ocurrio un error";
            this.alertTitle = "Error";
            this.show("top-right", "error");
            if(self.productosGuardar.length == self.contadorSetCreGasoinfo){
              this.alertMessage = "Completado";
              this.alertTitle = "";
              this.show("top-right", "success");
            }
          }
        },

      guardarEnviar(){
        var self = this;
        var productosGuardar = [];
        self.productosGuardar = [];
        var fechaFormat = moment(self.date).format("YYYY-MM-DD");
        
        for(var e = 0 ; e < self.registrosGasoinfoOriginal.length ; e++){

          if(self.registrosGasoinfoOriginal[e].AgregarAProductos){
            var estacion = {
                "Data": {
                  "Usuario": self.usuario,
                  "Gasolinera": self.registrosGasoinfoOriginal[e].CodGas,
                  "Estacion": self.registrosGasoinfoOriginal[e].Estacion,
                  "Fecha": fechaFormat,
                  "station": self.registrosGasoinfoOriginal[e].station,
                  "Productos": []
                }
              };

              productosGuardar.push(estacion);

          }
        }

        for(var s = 0 ; s < productosGuardar.length ; s++){
          
          for(var h = 0 ; h < self.registrosGasoinfoOriginal.length ; h++){

            if(productosGuardar[s].Data.Estacion == self.registrosGasoinfoOriginal[h].Estacion){
              
                var datosProducto = {};
                datosProducto.CodProducto = self.registrosGasoinfoOriginal[h].CodProducto;
                datosProducto.IdProductoGasoinfo = self.registrosGasoinfoOriginal[h].IdProductoGasoinfo;
                datosProducto.VtasDia = self.registrosGasoinfoOriginal[h].VtasDia;
                datosProducto.InvInicial = self.registrosGasoinfoOriginal[h].InvInicial;
                datosProducto.InvFinal = self.registrosGasoinfoOriginal[h].InvFinal;
                datosProducto.Compras = [];
                productosGuardar[s].Data.Productos.push(datosProducto);
                
            }

          }
        }




        

        
        for(var pg = 0 ; pg < productosGuardar.length ; pg++){
          
          for(var gi = 0 ; gi < self.registrosGasoinfoOriginal.length ; gi++){

            if(productosGuardar[pg].Data.Estacion == self.registrosGasoinfoOriginal[gi].Estacion && self.registrosGasoinfoOriginal[gi].Compras != null){
                

              for(var pgp = 0 ; pgp < productosGuardar[pg].Data.Productos.length ; pgp++){
                if(productosGuardar[pg].Data.Productos[pgp].CodProducto == self.registrosGasoinfoOriginal[gi].CodProducto){
              debugger; // eslint-disable-line no-debugger
                  // productosGuardar[pg].Data.Productos[pgp].Compras = self.registrosGasoinfoOriginal[gi].Compras;
                  productosGuardar[pg].Data.Productos[pgp].Compras = [];
                  for(var com = 0; com < self.registrosGasoinfoOriginal[gi].Compras.length ;com++){
                    if(self.registrosGasoinfoOriginal[gi].Compras[com].Enviar){
                      productosGuardar[pg].Data.Productos[pgp].Compras.push(self.registrosGasoinfoOriginal[gi].Compras[com]);
                    }
                  }
                }

              }

              
            }

          }
        }

        //productosGuardar[pg].Data.Productos[pgp].Compras

        for(var a = 0 ; a < productosGuardar.length ;a++){
          for(var b = 0; b < productosGuardar[a].Data.Productos.length ; b++){
            if(productosGuardar[a].Data.Productos[b].Compras != null){
              for(var c = 0; c < productosGuardar[a].Data.Productos[b].Compras.length ;c++){
                productosGuardar[a].Data.Productos[b].Compras[c].Enviar = 1;
              }
            }
          }
        }

        self.productosGuardar = productosGuardar;
        debugger; // eslint-disable-line no-debugger
        self.contadorSetCreGasoinfo = 0;
        if(productosGuardar.length > 0){
          
          for(var g = 0 ; g < productosGuardar.length ; g++){
        debugger; // eslint-disable-line no-debugger
            self.SetCreGasoinfo(productosGuardar[g]);
          }

        }else{
              this.alertMessage = "Seleccione estaciones";
              this.alertTitle = "";
              this.show("top-right", "success");
        }
      },
      calcularEnviar(indexCabecera){
        var self = this;
        
        self.enviarCheck(indexCabecera, null);
        self.SetCreVolumen(indexCabecera);

      },
      async SetCreVolumen(indexCabecera){
        var self = this;
        self.textoBotonAplicar = "Aplicando...";
        self.loading = true;
        var dataToSend = {
              Data: {
                "Fecha" : self.intDate,
                "Producto" : self.registrosGasoinfoOriginal[indexCabecera].CodProducto,
                "Estacion" : self.registrosGasoinfoOriginal[indexCabecera].Estacion,
                "Venta" : self.registrosGasoinfoOriginal[indexCabecera].VtasDia,
                "InvInicial" : self.registrosGasoinfoOriginal[indexCabecera].InvInicial,
                "InvFinal" : self.registrosGasoinfoOriginal[indexCabecera].InvFinal,
                "Merma" : self.registrosGasoinfoOriginal[indexCabecera].Merma
              }
            };
          try {
            const response = await axios.post("CRE/SetCreVolumen", dataToSend);
            
        self.loading = false;
            if (response.data.Success) {
              this.alertMessage = response.data.Message;
              this.alertTitle = "";
              this.show("top-right", "success");

            }else{
              
              this.alertMessage = response.data.Message;
              this.alertTitle = response.data.Error;
              this.show("top-right", "error");
            }
            self.textoBotonAplicar = "Aplicar";
            

          } catch (e) {
        self.loading = false;
            this.alertMessage = "Ocurrio un error";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          }
        },

        
        async aplicarCheckCallAPI(indexCabecera){
          var self = this;
          
          self.textoBotonAplicar = "Aplicando...";
          var dataToSend = {
                Data: {
                  "Id": self.registrosGasoinfoOriginal[indexCabecera].Id,
                  "Estatus": self.registrosGasoinfoOriginal[indexCabecera].Aplicar ? 1:0
                }
              };
              
            debugger; // eslint-disable-line no-debugger
            try {
              const response = await axios.post("Compras/SetCompraEnviar", dataToSend);
              
              if (!response.data.Success) {
                this.alertMessage = response.data.Error+". "+response.data.Message;
                this.alertTitle = "Error";
                this.show("top-right", "error");

              }
              self.textoBotonAplicar = "Aplicar";
              

            } catch (e) {
              this.alertMessage = "Ocurrio un error";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            }
        },


        async enviarCheckCallAPI(indexCabecera, indexDetalle){
          var self = this;
          self.enviarCheck(indexCabecera, indexDetalle);
          self.textoBotonAplicar = "Aplicando...";
          var dataToSend = {
                Data: {
                  "Id": self.registrosGasoinfoOriginal[indexCabecera].Compras[indexDetalle].Id,
                  "Estatus":self.registrosGasoinfoOriginal[indexCabecera].Compras[indexDetalle].Enviar?1:0
                }
              };
              
        debugger; // eslint-disable-line no-debugger
            try {
              const response = await axios.post("Compras/SetCompraEnviar", dataToSend);
              
              if (!response.data.Success) {
                this.alertMessage = response.data.Error+". "+response.data.Message;
                this.alertTitle = "Error";
                this.show("top-right", "error");

              }
              self.textoBotonAplicar = "Aplicar";
              

            } catch (e) {
              this.alertMessage = "Ocurrio un error";
              this.alertTitle = "Error";
              this.show("top-right", "error");
            }
        },
      enviarCheck(indexCabecera, indexDetalle){
        var self = this;
        
        debugger; // eslint-disable-line no-debugger
        if(indexDetalle != null){
          self.registrosGasoinfoOriginal[indexCabecera].Compras[indexDetalle].Enviar = !self.registrosGasoinfoOriginal[indexCabecera].Compras[indexDetalle].Enviar;
        }

        if(self.registrosGasoinfoOriginal[indexCabecera].Compras != null){
          
          self.registrosGasoinfoOriginal[indexCabecera].VolumenCompras = 0;
          for(var n = 0 ; n < self.registrosGasoinfoOriginal[indexCabecera].Compras.length ; n++ ){
            if(self.registrosGasoinfoOriginal[indexCabecera].Compras[n].Enviar){
              self.registrosGasoinfoOriginal[indexCabecera].VolumenCompras += self.registrosGasoinfoOriginal[indexCabecera].Compras[n].Cantidad;
            }
          }

        }


        self.recalcularMerma(indexCabecera);
      },
      recalcularMerma(index){
        var self = this;
        
          debugger; // eslint-disable-line no-debugger
          var InvFinal = parseFloat(self.registrosGasoinfoOriginal[index].InvFinal);
          var InvInicial = parseFloat(self.registrosGasoinfoOriginal[index].InvInicial);
          var VolumenCompras = parseFloat(self.registrosGasoinfoOriginal[index].VolumenCompras);
          var VtasDia = parseFloat(self.registrosGasoinfoOriginal[index].VtasDia);
          self.registrosGasoinfoOriginal[index].Merma = InvFinal - (InvInicial + VolumenCompras - VtasDia);
        
        // var elementButton = document.getElementById("calcular-"+index);
        // debugger; // eslint-disable-line no-debugger
        // elementButton.click();
      },
      seleccionarTodos(todos){
        var self = this;
        for(var n = 0; n < self.datosModal.length; n++){
          self.datosModal[n].Seleccionado = !todos;
        }
  
      },
      
      seleccionarTransaccion(index, transaccion, seleccionado){
        var self = this;
        self.datosModal[index].Seleccionado = !seleccionado;
      },
      
      async getCiudades(){
          try{
          const response = await axios.get('Catalogos/GetCiudades');
          //debugger; // eslint-disable-line no-debugger
          if(response.data.Success){
            if(response.data.response.length == 0){
              this.alertMessage = "No se encontró información.";
              this.alertTitle = "Error";
              this.show('top-right', 'error');
            }
          }else{
              console.log("error")
              this.alertMessage = response.data.Error;
              this.alertTitle = "Error";
              this.show('top-right', 'error');
          }
          this.ciudades = response.data.response;
        }catch(e){
          //this.alert = true;
          //this.error = 'Invalid email/password';
        }
      },
      async getEstaciones(){
          try{
          debugger; // eslint-disable-line no-debugger
          const response = await axios.post('Estacion/GetEstaciones?ciudad='+this.ciudadSelect);
          if(response.data.Success){
            if(response.data.response.length == 0){
              this.alertMessage = "No se encontró información.";
              this.alertTitle = "Error";
              this.show('top-right', 'error');
            }
          }else{
              console.log("error")
              this.alertMessage = response.data.Error;
              this.alertTitle = "Error";
              this.show('top-right', 'error');
          }
          this.estaciones = response.data.response;
          this.productos = [];
          this.getProductos();
        }catch(e){
          //this.alert = true;
          //this.error = 'Invalid email/password';
        }
      },
    show (group, type = '') {
        const text = this.alertMessage;
        this.$notify({
            group,
            title: this.alertTitle,
            text,
            type,
            data: {
                randomNumber: Math.random()
            }
        })
    },
    clean (group) {
        this.$notify({ group, clean: true })
    },
    swalNotification(swalType) {
        var btnClass = (swalType == 'error') ? 'danger' : swalType;
            btnClass = (swalType == 'question') ? 'primary' : btnClass;
        this.$swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover this imaginary file!',
            type: swalType,
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
            cancelButtonText: 'Cancel',
            confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
            cancelButtonClass: 'btn btn-default',
        })
    },
        async getData(){

        debugger; // eslint-disable-line no-debugger
        var self = this;
        self.loading = true;
        self.textoBotonBuscar = "Buscando...";
        try{
          self.intDate = self.date.replace('/', '');
          self.intDate = self.intDate.replace('/', '');
          
          var ciudad = self.ciudadSelect!=undefined?self.ciudadSelect:0;
          var estacion = self.estacionSelect!=undefined?self.estacionSelect:0;
          var plaza = "Tijuana";
          for(var c = 0 ; c<self.ciudades.length ; c++){
            if(self.ciudades[c].Id == ciudad){
              plaza = self.ciudades[c].Nombre;
            }
          }
          debugger; // eslint-disable-line no-debugger
          const response = await axios.post('CRE/GetCREGasoinfo' ,{
          "Data": {
              "Fecha" : self.intDate,
              "Plaza" : plaza,
              "Estacion" : estacion     
            }
          });
          
          debugger; // eslint-disable-line no-debugger
          if(response.data.Success){
            self.registrosGasoinfoOriginal = response.data.response;
            self.reordernarJSON();
            
          }else{
            self.registrosGasoinfoOriginal = [];
              console.log("error")
              self.alertMessage = response.data.Message;
              self.alertTitle = "Error";
              self.show('top-right', 'error');
          }
          
  
        self.textoBotonBuscar = "Buscar";
        
        self.loading = false;
        }catch(e){
            self.loading = false;
            self.registrosGasoinfoOriginal = [];
          //this.alert = true;
          //this.error = 'Invalid email/password';
        }
  
        },
        expandirDetalleCompras(index){
          debugger; // eslint-disable-line no-debugger
          var self = this;
          if(self.registrosGasoinfoOriginal[index].ExpandirDetalle){
            document.getElementById("detalle-"+index).style.display = "none";
            self.registrosGasoinfoOriginal[index].ExpandirDetalle = false;
          }else{
            document.getElementById("detalle-"+index).style.display = "block";
            self.registrosGasoinfoOriginal[index].ExpandirDetalle = true;
          }
          
        },
        reordernarJSON(){
          var self = this;
          for(var n = 0 ; n < self.registrosGasoinfoOriginal.length ; n++){
            self.registrosGasoinfoOriginal[n].ExpandirDetalle = false;
            self.registrosGasoinfoOriginal[n].AgregarAProductos = false;
            
            if(self.registrosGasoinfoOriginal[n].Compras != null){
              for(var d = 0 ; d < self.registrosGasoinfoOriginal[n].Compras.length ; d++){
                self.registrosGasoinfoOriginal[n].Compras[d].Enviar = self.registrosGasoinfoOriginal[n].Compras[d].Enviar == 1? true: false;
              }
            }
          }
        },
      async search(e) {
        e.preventDefault();
        this.getData();
        
      },
    },
    data() {
      return {
        contadorSetCreGasoinfo:0,
        productosGuardar: [],
        textoBotonAplicar: 'Aplicar',
        contador: 0,
        seleccionarTodosValue: true,
        vendedorSeleccionado:0,
        vendedores: [],
        datosModal:[],
        turnoText: "1",
        TipoCambio: 0,
        turnos:[],
        turnoSelected:{
          IdTurno:11
        },
        loading:false,
        nombreLogueado:"",
        idUsuario:undefined,
        reportefajillas:[],
        deshabilitarCombos:false,
        ciudades: [],
        estaciones: [],
        ciudadSelect: 1,
        estacionSelect: 0,
        ventaDetalle:[],
        emailReenviar:"",
        uuidReenviar:"",
        date: moment().format("YYYY/MM/DD"),
        intDateEnd: "",
        dateEnd: moment().format("YYYY/MM/DD"),
        intDate: "",
        options: {
          format: 'YYYY/MM/DD',
          useCurrent: false,
        },
        puestoSelected:{
            puesto:0
        },
        rolSelected:{
            rol:0
        },
        actualizarNIP:false,
        puestosSelect:[],
        rolesSelect:[],
        nuevoCorreo: '',
        clienteNuevo: true,
        clienteModal:{},
        textoBotonGuardar: "Guardar",
        textoBotonBuscar: "Buscar",
        clienteBuscar: "",
        items: [],
        registrosGasoinfoOriginal: [],
        registrosGasoinfoReordenado: [],
      };
    },
  };
  </script>
  <style scoped>
  .loading-search{
    position: absolute;
      width: 100%;
      left: 0px;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      z-index: 1000;
      padding-top: 150px;
      top: 0;
  }
  .panel-body-m{
          margin: 1em !important;
  }
  
  
  
  body{
    background-color: #aaa !important;
  }

  .separadorDiv{
    border-top: 2px solid black !important;
  }
  </style>