<template>
  <div>
    <!-- BEGIN login -->
    <div class="login login-v2 fw-bold">
      <!-- BEGIN login-cover -->
      <div class="login-cover">
        <div
          class="login-cover-img"
          v-bind:style="{ backgroundImage: 'url(' + bg.activeImg + ')' }"
        ></div>
        <div class="login-cover-bg"></div>
      </div>
      <!-- END login-cover -->

      <!-- BEGIN login-container -->
      <div class="login-container">
        <!-- BEGIN login-header -->
        <div class="login-header">
          <div class="brand">
            <!-- <div class="d-flex align-items-center">
<img src="/assets/img/logo/logo_rendichicas.png" alt="">
</div> -->
            <div class="d-flex align-items-center"><b>ADDES</b> Web</div>
            <small>Administración</small>
          </div>
          <div class="icon">
            <i class="fa fa-lock"></i>
          </div>
        </div>
        <!-- END login-header -->

        <!-- BEGIN login-content -->
        <div class="login-content">
          <div class="note note-danger note-with-end-icon mb-2" v-if="alert">
            <div class="note-icon"><i class="fa fa-frown"></i></div>
            <div class="note-content">
              <h4><b>Oops!</b></h4>
              <p>{{ error }}</p>
            </div>
          </div>
          <form v-on:submit="handleSubmit" method="GET">
            <div class="form-floating mb-20px">
              <input
                type="text"
                class="form-control fs-13px h-45px border-0"
                placeholder="Email"
                id="emailAddress"
                v-model="email"
              />
              <label
                for="emailAddress"
                class="d-flex align-items-center text-gray-600 fs-13px"
                >Usuario</label
              >
            </div>
            <div class="form-floating mb-20px">
              <input
                type="password"
                class="form-control fs-13px h-45px border-0"
                placeholder="Password"
                v-model="password"
              />
              <label
                for="emailAddress"
                class="d-flex align-items-center text-gray-600 fs-13px"
                >Contraseña</label
              >
            </div>
            <!-- <div class="form-check mb-20px">
							<input class="form-check-input border-0" type="checkbox" value="1" id="rememberMe" />
							<label class="form-check-label fs-13px text-gray-500" for="rememberMe">
								Remember Me
							</label>
						</div> -->
            <div class="mb-20px">
              <button
                type="submit"
                class="btn btn-success d-block w-100 h-45px btn-lg"
              >
                Ingresar
              </button>
            </div>
            <!-- <div class="text-gray-500">
							No tienes una cuesta? Haz clic <router-link to="register" class="text-white">aquí</router-link> para registrarte.
						</div>
						<div class="text-gray-500">
							Olvidaste tu contraseña? Clic <router-link to="forgot" class="text-white">aquí</router-link>.
						</div> -->
          </form>
        </div>
        <!-- END login-content -->
      </div>
      <!-- END login-container -->
    </div>
    <!-- END login -->

    <!-- BEGIN login-bg -->
    <!-- <div class="login-bg-list clearfix">
			<div class="login-bg-list-item" v-bind:class="{ 'active': bg.bg1.active }"><a href="javascript:;" class="login-bg-list-link" v-on:click="select('bg1')" style="background-image: url(/assets/img/login-bg/login-bg-17.jpg)"></a></div>
			<div class="login-bg-list-item" v-bind:class="{ 'active': bg.bg2.active }"><a href="javascript:;" class="login-bg-list-link" v-on:click="select('bg2')" style="background-image: url(/assets/img/login-bg/login-bg-16.jpg)"></a></div>
			<div class="login-bg-list-item" v-bind:class="{ 'active': bg.bg3.active }"><a href="javascript:;" class="login-bg-list-link" v-on:click="select('bg3')" style="background-image: url(/assets/img/login-bg/login-bg-15.jpg)"></a></div>
			<div class="login-bg-list-item" v-bind:class="{ 'active': bg.bg4.active }"><a href="javascript:;" class="login-bg-list-link" v-on:click="select('bg4')" style="background-image: url(/assets/img/login-bg/login-bg-14.jpg)"></a></div>
			<div class="login-bg-list-item" v-bind:class="{ 'active': bg.bg5.active }"><a href="javascript:;" class="login-bg-list-link" v-on:click="select('bg5')" style="background-image: url(/assets/img/login-bg/login-bg-13.jpg)"></a></div>
			<div class="login-bg-list-item" v-bind:class="{ 'active': bg.bg6.active }"><a href="javascript:;" class="login-bg-list-link" v-on:click="select('bg6')" style="background-image: url(/assets/img/login-bg/login-bg-12.jpg)"></a></div>
		</div> -->
    <!-- END login-bg -->
  </div>
</template>

<script>
import axios from "axios";
import AppOptions from "../../config/AppOptions.vue";

export default {
  beforeCreate() {
    // console.log('beforeCreate')
  },
  name: "Login",
  created() {
    AppOptions.appEmpty = true;
  },
  mounted() {
    var userSetting = localStorage.getItem("userSettings");
    if (userSetting != undefined) {
      // console.log(JSON.parse(userSetting));
      // this.pageUserSettings = JSON.parse(userSetting);
      this.$router.push("/").catch(() => {});
    }
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;
    next();
  },
  data() {
    return {
      email: "",
      password: "",
      error: "",
      alert: false,
      bg: {
        activeImg: "/assets/img/login-bg/login-bg-17.jpg",
        bg1: {
          active: true,
          img: "/assets/img/login-bg/login-bg-17.jpg",
        },
        bg2: {
          active: false,
          img: "/assets/img/login-bg/login-bg-16.jpg",
        },
        bg3: {
          active: false,
          img: "/assets/img/login-bg/login-bg-15.jpg",
        },
        bg4: {
          active: false,
          img: "/assets/img/login-bg/login-bg-14.jpg",
        },
        bg5: {
          active: false,
          img: "/assets/img/login-bg/login-bg-13.jpg",
        },
        bg6: {
          active: false,
          img: "/assets/img/login-bg/login-bg-12.jpg",
        },
      },
    };
  },
  methods: {
    redirectTo(path){
      this.$router.push({ path: path })
    },
    async handleSubmit() {
      //forLogin
    var self = this;
      var dataSend = {
        Data: {
          TipoLogin: 2,
          Usuario: this.email,
          Pass: this.password,
        },
      };
      try {
        const response = await axios.post("Colaborador/Login", dataSend);

        if (response.data.Success) {
          localStorage.setItem(
            "userSettings",
            JSON.stringify(response.data.response)
          );

          // console.log(JSON.stringify(response.data.response))
          //this.$store.dispatch('user', response.data.user);
          // this.$router.push('/');

          var permisos = response.data.response.Permisos;
          var path = "";
          var loop = true;
          for (var n1 = 0; n1 < permisos.length; n1++) {
            for (var n2 = 0; n2 < permisos[n1].Submodulos.length; n2++) {
              for (
                var n3 = 0;
                n3 < permisos[n1].Submodulos[n2].Permisos.length;
                n3++
              ) {
                // debugger; // eslint-disable-line no-debugger
                if (
                  permisos[n1].Submodulos[n2].Permisos[n3].Path ==
                  "reportes/cre/xmlcre"
                ) {
                  path = "/reportes/cre/xmlcre";
                  loop = false;
                } else if (loop) {
                  path = "/" + permisos[0].Submodulos[0].Permisos[0].Path;
                  if(permisos[n1].Descripcion == "Tanques"){
                    path = "/" + permisos[n1].Path;
                  }
                }
              }
            }
          }
          console.log(path);
          //self.$router.go();

          debugger; // eslint-disable-line no-debugger
          // self.$router.go({
          //   path: path,
          // });
        
          setTimeout(function(){
            self.redirectTo(path);
          },0);

        } else {
          localStorage.removeItem("userSettings");
          console.log("not found");
          alert("Usuario o contraseña incorrectos");
        }
      } catch (e) {
        // this.alert = true;
        // this.error = ;
        alert("Usuario o contraseña incorrectos");
      }
    },
    // select: function(x) {
    // 	this.bg.bg1.active = false;
    // 	this.bg.bg2.active = false;
    // 	this.bg.bg3.active = false;
    // 	this.bg.bg4.active = false;
    // 	this.bg.bg5.active = false;
    // 	this.bg.bg6.active = false;

    // 	switch (x) {
    // 		case 'bg1':
    // 			this.bg.bg1.active = true;
    // 			this.bg.activeImg = this.bg.bg1.img;
    // 			break;
    // 		case 'bg2':
    // 			this.bg.bg2.active = true;
    // 			this.bg.activeImg = this.bg.bg2.img;
    // 			break;
    // 		case 'bg3':
    // 			this.bg.bg3.active = true;
    // 			this.bg.activeImg = this.bg.bg3.img;
    // 			break;
    // 		case 'bg4':
    // 			this.bg.bg4.active = true;
    // 			this.bg.activeImg = this.bg.bg4.img;
    // 			break;
    // 		case 'bg5':
    // 			this.bg.bg5.active = true;
    // 			this.bg.activeImg = this.bg.bg5.img;
    // 			break;
    // 		case 'bg6':
    // 			this.bg.bg6.active = true;
    // 			this.bg.activeImg = this.bg.bg6.img;
    // 			break;
    // 	}
    // }
  },
};
</script>